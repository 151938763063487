import React from 'react'
import Faq from '../Faq/Faq'
import './Patient.css'
import patientIcon from './patient.svg'
import mobIcon from './Mask group.svg'


const Patient = () => {



    return (
        <div className='patient'>
            <div className='patient-icon'>
                <img src={patientIcon} alt='patient-icon' ></img>
            </div>
            <h3>Alivius for Patients</h3>
            <div className='grid-container'>
                <div className='grid-left'>

                    <img src={mobIcon} alt='mobile-app' ></img>
                </div>
                <div className='grid-right'>
                    <p>
                        Cancer is a complex condition where you may need help in every steps throughout your journey. Alivius is a patient-centric digital solution which enables users to take control of their lives during their treatment journey.
                        <br></br>
                        <br></br>

                        Your Hospital invites you to Alivius, post which:
                    </p>
                    <div>
                        <ul>
                            <li>You will be able to connect with hospital seamlessly,</li>
                            <li>Login your everyday health including symptoms & moods,</li>
                            <li>Ensure a healthier lifestyle with the nutritional and mental wellness support provided by the hospital</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div className='download'>
                <p>Contact your hospital and download the app to get started.</p>
            </div>

            <h3 className='faq-heading'>
                Key FAQs for Patients:
            </h3>

            <div className='faq-'>
                <Faq q='Q. How do I get access to Alivius?' a='A. If you are suffering from cancer, you may talk to your oncologist or the hospital team to see if they are using Alivius. If they are, they would have to invite you to join Alivius.' />
                <Faq q='Q. How do I install the Alivius app?' a='A. Once your hospital invites you to join Alivius, you will get an SMS with an invitation link to download the app from Google Play Store / Apple Store. You can then download and access the app.' />
                <Faq q=' Q. How does Alivius help me?' a='A. Alivius is a technology platform which connects you with the hospital building a seamless and effective communication channel, while the overall care being driven by your hospital.' />
                <Faq q='Q. I am a Cancer patient and is currently in Brazil, can I get access to Alivius?' a='A. Alivius is currently available in Colombia and Brazil. Speak to your hospital to see if they are using Alivius. Your hospital can invite you to access Alivius.' />
            </div>

        </div>
    )
}

export default Patient