import React from 'react'
import './Landing.css'
import landingIcon from './Rectangle 36.svg'
import midAgedWoman from './Rectangle 36.png'


const Landing = () => {
  return (
    <div className='landing'>

      <h1>
        With you at every step <span>of your journey.</span>
      </h1>

      <img className='landing-icon' src={landingIcon} alt='landing-icon' ></img>

      <p>In the fight against cancer, cancer is not the only enemy.</p>

      <img className='middle-aged-woman' src={midAgedWoman} alt='middle-aged-woman' ></img>

    </div>
  )
}

export default Landing