import React from 'react'
import footerIcon from './cancer-ribbon 1.svg'
import './Footer.css'
import { Link } from 'react-router-dom'
// import bla from '../../pages/'
const Footer = () => {
  return (
      <div className='footer'>
          <div className='footer-icon'>
              <img src={footerIcon} alt='footer-icon'></img>
          </div>
          <div className='footer-content'>
              <p className='contact'>Feel free to get in touch with us <span>1800 102 8227</span></p>
              <ul>
                  <li><Link to='/privacy-policy' style={{color: "white", textDecoration: "none"}} >Privacy Policy</Link></li>
                  <li><Link to='/terms-and-conditions' style={{color: "white", textDecoration: "none"}} >Terms and Conditions</Link></li>
              </ul>
              <p className='brand'>Powered by SVAAS WELLNESS LIMITED</p>
              
          </div>
      </div>
  )
}

export default Footer