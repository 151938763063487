import React from 'react';

const PdfViewer = ({ pdfUrl }) => {
    return (
      <div style={{ width: '100%', height: '100vh' }}>
        <iframe
          title="PDF Viewer"
          src={pdfUrl}  // Directly use the pdfUrl here
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </div>
    );
};

export default PdfViewer;