import React from 'react'
import './Hospital.css'
import medic from '../Hospitals/medical-team.svg'
import vitals from './Vitals 1.png'
import vitalSVG from './Vitals 1.svg'

const Hospital = () => {
  return (
    <div className='hospital'>
      <img className='medic-team' src={medic} alt='medical-team' ></img>
      <h3>Alivius For Hospitals</h3>
      <div className='vital-svg'>
        <div className='img-container'>
          <img src={vitalSVG} alt='vitals-report' ></img>
        </div>
      </div>
      <div className='content-container'>
        <div className='left-container'>
          <p>
            Alivius empowers care teams to provide integrated care effortlessly. With Alivius, they will be able to focus on not just the medical care but the 360 degree view of the physical and functional health of the patient.
          </p>
          <ul className='list-of-item'>
            <li>Remote Monitor your patients</li>

            <li>Build a secure and efficient communication channel with your patients</li>

            <li>Counsel patients on mental health</li>

            <li>Advise patients on nutritional recommendations</li>
          </ul>
          <a className='desk-a' href='/' >Hospital Users can login here</a>
          <a className='mob-a' href='/' >Hospital Users login </a>
        </div>

        <div className='right-container'>
          <img src={vitals} alt='vitals-report' ></img>
        </div>


      </div>
    </div>
  )
}

export default Hospital