import React from 'react'
import './Header.css'
import logo from './Logo.svg'
import menu from './menu.svg'

const Header = () => {
  return (
    <div className='logo'>
      <div className='wrapper-logo' >
        <img className='brandLogo' src={logo} alt='brandLogo' ></img>
        <img className='menu' src={menu} alt='menu' ></img>
      </div>
    </div>
  )
}

export default Header