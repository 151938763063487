import React from 'react'
import './intro.css'

const Intro = () => {
  return (
      <div className='intro'>
          <h3>Alivius Introduction</h3>
          <p>Alivius is an integrated digital platform for Cancer patients to help them navigate through the healthcare system from the point of diagnosis through the entire treatment. Alivius empowers Care Teams to provide integrated care effortlessly.</p>
      </div>
  )
}

export default Intro