export const PrivacyPolicyEn = () => {
    return (<div className='container' style={{padding: '1rem'}}>
    
<h3 style={{textAlign: 'center', marginBottom: '3rem'}} >Privacy Notice</h3>

    <p>
        This mobile application “ALIVIUS" ("Application", “us” or "we") is owned, operated and managed by <strong>SVAAS 
            WELLNESS LIMITED</strong>, a company incorporated under the laws of India, having its    registered office at 8-2-
        337, Road No. 3, Banjara Hills, Hyderabad-500034, Telangana (“we”, “Company”, “Svaas”   “us”, “ourselves” 
        or “our”)
    </p>

    <p>
        The Application is a platform that offers services that aim at helping and addressing an End User’s query 
        on lifestyle and disease management and/or to follow up on patient’s adherence to the prescribed 
        treatment and/or to seek update about the Patients’ physical wellbeing by the Institution (collectively, the 
        “<strong>Services</strong>”). By continuing using the Services, you agree to this Privacy Notice. Please carefully read this 
        Privacy Notice and if you have any questions in relation to the processing of your personal information or 
        this notice, please reach out to us at support@myalivius.com
    </p>

    <p>
        Upon your registration at the Application, Svaas is responsible for the processing of your personal 
        information in and during your use of the Application, as it decides why and how it is processed, thereby 
        acting as the “Data Fiduciary” of such personal information. This Privacy Notice describes the ways in which 
        we process your personal information in relation to the services we offer, measures we have put in place to 
        protect your information and how can you exercise your rights.
    </p>

    <p>
        Do take note that if you access any third-party link or website from our Application, you may need to refer 
        to the privacy policies of such third parties. We do not endorse and are not responsible for the information 
        or privacy practices of websites or services owned by third parties. You will be subject to the data privacy 
        policies and procedures of such third parties and if you share any data other than as required for availing 
        the services through the Application, it shall be at your own risk and responsibility.
    </p>

    <p><strong>Types of Personal Information collected and processed</strong>
    </p>

    <p>
        We (and our service providers) collect and process the following categories of personal information:
    </p>    

    <ul style={{padding: '2rem'}}>
        <li>
            <strong>Contact Information</strong>: We collect and use your name, date of birth, mobile number, gender, name of 
            the Institution and name of the Health Care Practitioner, care giver details such as (name, gender, 
            relationship, mobile number) directly or indirectly, which is filled by the institution staff on your behalf, 
            for the purpose of signing up to our Application, to provide the Services, and to provide support when 
            you contact us. All of the foregoing are referred to as “User Data”.    
        </li>

        <li>
            <strong>Health Data</strong>: While availing the services through the Application, your data relating to your physical 
            wellbeing and health will be uploaded on the tool by the authorized representative of the Institution. 
            You may do so primarily by sharing your personal information directly with your health care provider 
            or the Institution. This information may include but is not limited to information relating to any illness 
            or physical or physiological condition or mental or emotional state or your medical history as well as 
            on key information about the medicines you are taking, allergies you suffer from and any adverse reactions to 
            medicines you have had in the past. Such data is referred to as “health data”.
        </li>

        <li>
            <strong>Technical Information</strong>: Subsequently, during your use of the Application, we may collect and process 
            the following information, (i) technical information including IP address, login information, type and 
            version of operating system and unit, time settings, language settings etc; and (ii) information about 
            the Services we provide to you such as how often you use the Application and which functionality you 
            use the most.
        </li>
        
        <li>
            <strong>Optional Services</strong>: We may send you news, updates and promotional content by text messages (via SMS 
            and/or Whatsapp) and other electronic communications channels, such as push notifications and in-App 
            messages. Such communications may be based on what we know about you as a user and our understanding 
            of how you use the Application and the Services, for example which features you tend to use, and which prior 
            communications you have showed an interest in, searches you have made, your various contacts with the third 
            party service providers as well as basic demographic and geographic data about you, such as your age, gender 
            and the region in which you reside. However, please note that these are optional services, and you may 
            choose to opt-out from receiving these messages in the Application. You may also write to us <a href="mailto:support@myalivius.com">support@myalivius.com</a> in case you wish to opt-out from receiving these messages.
        </li>

        <li>
            <strong>Aggregated Information</strong>: We may use your information including that involving your interaction with our 
            Application in an aggregated or anonymized form. We will use this information for the purpose of analyzing 
            the outcomes and in turn improve your overall experience with our Application, and the associated services.
        </li>
    </ul>

    <p><strong>Sharing/Transferring of your Personal Information:</strong></p>

    <p>
        The Services provided by or through the Application are rendered by us with the help of several third-parties that 
        we engage with to provider Services to you. Some of these third-parties may act independently as Data Controller 
        and/or as ‘Data processors’. These Third-parties and are contractually required to protect your personal information 
        and comply with applicable laws.
    </p>

    <p>The Third-parties who will access your personal Information are:</p>

    <ul style={{padding: '2rem'}}>
        <li>
            The third-party staff who would be providing on-boarding services and customer service support.
        </li>
        <li>
            By providing value added services/access to our newsletters, wellness tips and for sharing marketing
            and promotional campaigns.
        </li>
        <li>
            The technical support staff who would be providing technical support services such as bug resolution
            and any other application issues, on need basis to the users.
        </li>
        <li>
            Any law enforcement agency, regulator, or court, if required to comply with the applicable law.
        </li>
    </ul>

    <p>We may also share your personal information with third parties to</p>

    <p>
        <p style={{paddingLeft: '3rem'}}>
        (i)    contact, address, communicate with you in order to share information, promote health behaviors,
        </p>
        <p style={{paddingLeft: '3rem'}}>
        (ii)    educate and apprise of our service offerings from time to time
        </p>
        <p style={{paddingLeft: '3rem'}}>
        (iii)    conduct analytics to provide us business insights enabling us to improve our services.
        </p>
    </p>

    <p>
        We also use third party service providers who process your personal information in their capacity as data processors
        (such as hosting, maintenance service providers) and act under our instructions, for the sole purpose of performing
        the services as contracted with us. We may also retain the services of suppliers who work independently and who,
        in this way, are independently responsible for the processing of your personal data, such as providers of payment
        solutions. Where applicable, you will be requested to enter into separate agreements directly with such suppliers.
        We ask you to please note that this Privacy Notice does not apply to the processing of personal information which
        takes place through these suppliers. For information regarding how other suppliers process your personal
        information, please contact these suppliers.
    </p>

    <p>
        We respect your privacy and therefore do not sell your personal information collected through this Application to
        any third parties.
    </p>

    <p class="no-bot-margin" ><strong>Purpose of use of your Personal Information:</strong></p>
    <p class="no-top-margin">We process your personal information for the following purposes:</p>

    <p>
        <p style={{paddingLeft: '3rem'}}>
            (a) To process your Registration or terminate your account in the Application;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (b) To provide you with authorisation to log-in and operate your account;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (c) To verify your identity and age;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (d) To maintain correct and up-to-date information about you;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (e) To enable you to monitor and avail the Services as subscribed for;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (f) To measure and analyse your use of the Application and to improve the Application and the
            Services;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (g) To handle your choice of settings and payment options;
        </p>

        <p style={{paddingLeft: '3rem'}}>
            (h) To otherwise be able to provide the Services according to the terms and conditions, that this notice
            by reference, forms part of.
        </p>
    </p>

    <p>
        We may also process your personal information for other business purposes like analysis, audit monitoring, fraud
        and misuse prevention. We may process your personal information, within and outside India, if we are required to
        do so to comply with any applicable law, regulatory direction or court orders/subpoenas.
    </p>

    <p>
        The legal basis for processing your personal information by us is your consent, necessary for us to be able to provide
        you with the Services. We need to process your personal information for the performance of the terms and conditions as agreed between us. The processing of your personal information is also based on our legitimate
        interest to measure and analyse use of the Application and to improve the Application and the Services we provide
        to you.We and the third-party service providers may process your personal information for the purpose of
        developing and improving the Services and the IT systems used to provide the Services. This is done on the basis
        of our legitimate interests in continually improving the security and our handling of your personal information, to
        make the Application more user-friendly, for example by changing and personalising the user interface in order to
        simplify the user journey, or to highlight and improve functions which we deem relevant to our users. All other
        development of our Services takes place using anonymised data.
    </p>

    <p class="no-bot-margin"><strong>Storing of your Personal Information:</strong></p>

    <p class="no-top-margin">
        The personal information received by us or shared by you or obtained from you by our third -party service
        providers are stored only in India. The User Data may be stored by us at our subcontracted processor, while
        the health data is stored by our service providers at infrastructure within India. Where necessary, we or the
        third-party service providers may occasionally transfer your personal information outside India but only to
        the extent permitted under the appropriate data protection legislation as applicable to us, ensuring your
        personal information is protected at all times.
    </p>

    <p class="no-bot-margin">
        <strong>Protection of your Personal Information:</strong>
    </p>

    <p class="no-top-margin">
        We have implemented the appropriate technical and organizational measures to ensure your personal information
        remains protected. These measures have been designed to provide an adequate level of security, maintain
        confidentiality, and prevent unauthorized access to your personal information. The measures we implement meet
        the industry standards and ensure that they protect your personal information in an appropriate manner that is
        required depending on the nature of the personal information.
    </p>

    <p class="no-bot-margin">
        <strong>Retention of your Personal Information:</strong>
    </p>

    <p class="no-top-margin">
        We will only retain your personal information for as long as necessary to fulfil the purpose for which it was collected,
        to comply with legal or regulatory requirements or until we have received a request from you to delete such
        information.
    </p>

    <p class="no-bot-margin"><strong>Your Privacy Rights:</strong></p>

    <p class="no-top-margin">
        We take reasonable measures to process your personal information in a lawful manner. You have the following
        rights regarding your personal information, which you can exercise under the conditions and within the limits set
        forth in the applicable law:
    </p>

    <ul style={{padding: '2rem'}}>
        <li>
            to be informed as to why your personal information is needed for processing, how we use the data and
            what will be done with it;
        </li>
        <li>
            to access and obtain a copy of your data on request;
        </li>
        <li>
            to rectify and change any incorrect or incomplete data;
        </li>
        <li>
            to erase or stop processing your data, for example where the data is no longer necessary for the purposes
            of processing;
        </li>
        <li>
            to ask us to restrict processing data for a period if data is inaccurate or to request your personal
            information be erased (which may however impact your access to the Application and/or the delivery of
            the Services);
        </li>
        <li>
            to withdraw your consent at any time. Please note that such withdrawal shall not affect the legality of the
            processing of personal information based on the consent before such request is made;
        </li>
        <li>
            to request redressal for any grievances you may have related to the way your personal information is
            processed; and
        </li>
        <li>
            to nominate another individual to exercise your privacy rights on your behalf in case of your death or
            inability to do so.
        </li>
    </ul>

    <p>
        We will abide by your request unless we have compelling legitimate grounds for the processing which override
        your interests and rights, or if we need to continue to process the data for the establishment, exercise or defence
        of a legal claim or to comply with any applicable laws.
    </p>

    <p>
        All information, communication and all measures we carry out are free of charge for you. If, on the other hand, what
        you request due to your rights is manifestly unfounded or excessive, we have the right to charge an administrative
        fee to provide you with the information or carry out the requested action, or refuse to act on your request.
    </p>

    <p>
        To exercise your rights please reach out to us support@myalivius.com. If you have any concerns related to how
        your personal information is being processed, you can also write to our Data Privacy Officer at <a href="mailto:dataprivacy@drreddys.com">dataprivacy@drreddys.com</a>
    </p>

    <p><strong>Updates to this Privacy Notice:</strong></p>
    <p>
        We may change or update this Privacy Notice from time to time by posting a new privacy notice on this
        Application. Please keep checking this notice occasionally so that you are aware of any changes.
    </p>

    <i>Last Updated: January 11<sup>th</sup> 2024</i>
    
    </div>)
}

