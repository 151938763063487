import React from 'react'
import Header from './components/Header/Header'
import Landing from './components/Landing/Landing'
import Intro from './components/Intro/Intro'
import Hospital from './components/Hospitals/Hospital'
import Patient from './components/Patients/Patient'
import Footer from './components/Footer/Footer'

import { BrowserRouter, Routes, Route } from 'react-router-dom'


import './App.css'
import { PrivacyPolicyEn } from './components/PrivacyPolicy/en/pp-en'
import PdfViewer from './components/PdfViewer/PdfViewer'

const App = () => {
  return (

    <BrowserRouter>
    <Routes>
      <Route path="/" element={<>
      <Header />
      <Landing />
      <Intro />
      <Hospital />
      <Patient />
      <Footer />

    </>}> </Route>

      <Route path="/privacy-policy" element={<PdfViewer pdfUrl={'https://alivius-landing-page.s3.us-east-1.amazonaws.com/Alivius+PP+(Portuguese)+(281024).pdf'} />} ></Route>
      <Route path="/en/privacy-policy" element={<PrivacyPolicyEn />} ></Route>
      <Route
          path='/terms-and-conditions'
          element={<PdfViewer pdfUrl={'https://alivius-landing-page.s3.us-east-1.amazonaws.com/Alivius+T%26Cs+(Portuguese)+281024.pdf'} />}
        />
     </Routes>
      </BrowserRouter>

    
  )
}

export default App