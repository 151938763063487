import React from 'react'
import './Faq.css'

const Faq = (props) => {

    const { q, a } = props;



    return (
        <div className='qna'>
            <p className='ques'>{q}</p>

            <p className='ans'>{a}</p>

        </div>

    )
}

export default Faq